import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import * as _ from 'underscore';
import * as XLSX from 'xlsx';
import {SearchService} from './../../services/search.service';

export interface ErrorMessageType {
  message: string;
  type: string;
}

export enum ErrorType {
  applicationException = 'Application Exception',
  systemException = 'System Exception',
}

@Component({
  selector: 'app-scip-upload',
  templateUrl: './scip-upload.component.html',
  styleUrls: ['./scip-upload.component.css']
})
export class ScipUploadComponent implements OnInit, OnDestroy {

  customCollapsedHeight = '30px';
  customExpandedHeight = '35px';
  isLoading = false;
  subscriber: Subscription;
  errorMessageType: ErrorMessageType = null;

  clearErrorMessage() {
    this.errorMessageType = {
      message : null,
      type: null
    };
  }

  populateErrorMessage(message, type) {
    this.errorMessageType.message = message;
    this.errorMessageType.type = type;
  }

  constructor( private ss: SearchService) { }

  ngOnDestroy(): void {
    // tslint:disable-next-line:no-unused-expression
    this.subscriber && this.subscriber.unsubscribe();
  }

  ngOnInit() {
  }

  onSelect(event: any) {
    this.isLoading = true;
    this.clearErrorMessage();

    if (event.addedFiles[0].size < 1000000 ) {
      const target: DataTransfer = <DataTransfer>(event.addedFiles);
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(target[0]);
      reader.onload = (e: any) => {
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);



        if (data.length === 0) {
          this.populateErrorMessage('File cannot be empty.', ErrorType.applicationException);
          this.isLoading = false;
        } else if (data.length > 20001) {
          this.isLoading = false;
          this.populateErrorMessage('Please use < 15000 Part Numbers / VIN Numbers.', ErrorType.applicationException);
        } else if (this.hasLengthError(data)) {
          this.isLoading = false;
          // tslint:disable-next-line:max-line-length
          this.populateErrorMessage('VIN/PIN/Part Number length cannot be > 20  and Brand length cannot be > 50.', ErrorType.applicationException);
        } else {
          const morphedData = this.modifyBeforeSend(data);
          this.subscriber = this.ss.sendSCIPFileDetails(morphedData)
            .subscribe(res => {
              this.export(res);
            }, () => {
              this.isLoading = false;
              this.populateErrorMessage('Please use valid excel template.', ErrorType.applicationException);
            });
        }
      };
    } else {
      this.isLoading = false;
      this.populateErrorMessage('Please use file size < 1 mb.', ErrorType.applicationException);
    }
  }

  hasLengthError(data) {
    const hasError = _.every(data, (item) => {
      const vinLength = item['VIN / PIN / Part Number'].toString().length;
      const brandLength = item['Brand'].length;
      return vinLength > 0 && vinLength < 20 && brandLength > 0 &&  brandLength < 50;
    });
    return !hasError;
  }

  modifyBeforeSend(input) {
    const tempArray = [];

    _.each(input , function(item) {
      const tempObject = {};
      tempObject['brand'] = item['Brand'];
      tempObject['inputValue'] = item['VIN / PIN / Part Number'];
      tempArray.push(tempObject);
    });
    return tempArray;
  }

  modifyAfterReceive(input) {
    const tempArray = [];

    _.each(input , function(item) {
      const tempObject = {};
      tempObject['SCIP Number'] = item['scipnumber'];
      tempObject['Article Name'] = item['articlename'];
      tempObject['Primary Article Identifier Type'] = item['primaryarticleidentifiertype'];
      tempObject['Primary Article Identifier Value'] = item['primaryarticleidentifiervalue'];
      tempObject['Brand'] = item['brand'];
      tempObject['Remark'] = item['remark'];

      tempArray.push(tempObject);
    });
    return tempArray;
  }

  public export(res) {
    const morphedData = this.modifyAfterReceive(res);
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(morphedData);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book

    // XLSX.utils.sheet_add_aoa(workSheet, [
    //   ['', '']
    // ], {origin: -1});

    // XLSX.utils.sheet_add_aoa(workSheet, [["Created Date :" +new Date()]], {origin: -1});


    const date_ob = new Date();

// adjust 0 before single digit date
    const date = ('0' + date_ob.getDate()).slice(-2);

// current month
    const month = ('0' + (date_ob.getMonth() + 1)).slice(-2);

// current year
    const year = date_ob.getFullYear();

// current hours
    const hours = ('0' + date_ob.getHours()).slice(-2);

// current minutes
    const minutes = ('0' + date_ob.getMinutes()).slice(-2);

// current seconds
    const seconds = ('0' + date_ob.getSeconds()).slice(-2);

// prints date & time in YYYY-MM-DD HH:MM:SS format
// console.log(year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds);
    const fileName = `bulk-rerefence-${year}-${month}-${date}-${hours}-${minutes}-${seconds}.xlsx`;
    this.isLoading = false;
    XLSX.writeFile(workBook, fileName); // initiate a file download in browser
  }
}
