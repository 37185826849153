import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scip-dummy',
  templateUrl: './scip-dummy.component.html',
  styleUrls: ['./scip-dummy.component.css']
})
export class ScipDummyComponent {

}
