import {MaterialModule} from './shared/material/material.module';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScipSearchComponent} from './core/scip-search/scip-search.component';
import {ScipUploadComponent} from './core/scip-upload/scip-upload.component';
import {ScipDummyComponent} from './core/scip-dummy/scip-dummy.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ScipFaqComponent} from './core/scip-faq/scip-faq.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {NotifyComponent} from './core/notify/notify.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ScipHomeComponent} from './core/scip-home/scip-home.component';
import {LoadingComponent} from './core/loading/loading.component';
import {GeneralComponent} from './core/general/general.component';
import {WINDOW_PROVIDERS} from './window.providers';
import {HostNameService} from './services/sample.service';
import {InterceptorService} from './services/interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    ScipSearchComponent,
    ScipUploadComponent,
    ScipDummyComponent,
    ScipFaqComponent,
    NotifyComponent,
    ScipHomeComponent,
    LoadingComponent,
    GeneralComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDropzoneModule,
    FlexLayoutModule
  ],
  providers: [ WINDOW_PROVIDERS, HostNameService,{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  } ],
  entryComponents: [
    NotifyComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {

  }
}
