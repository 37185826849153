import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { environment } from 'src/environments/environment';
import { delay, timeout } from 'rxjs/operators';
import {EnvironmentService} from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  baseURL;
  options = {
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: false
  };

  constructor(private http: HttpClient, private env: EnvironmentService) {
    this.baseURL = this.env.getEnvironment();
   }

   getBrandNames() {
     return this.http.get(this.baseURL + `/SCIP/brands`);
   }

  getSingleSCIPDetails(payload) {
    const brand = payload.brand;
    const input = payload.partNumber;
     return this.http.get(this.baseURL + `/SCIP/referencenumber?brand=${brand}&inputValue=${input}`);
  }

  sendSCIPFileDetails(data) {
    return this.http.post(this.baseURL + '/SCIP/multireferencenumber', data ,this.options);
  }

  testAPI() {
    return this.http.get(this.baseURL + '/demo', {responseType: 'text'});
  }

}
