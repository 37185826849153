import { ScipFaqComponent } from './core/scip-faq/scip-faq.component';
import { ScipDummyComponent } from './core/scip-dummy/scip-dummy.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScipHomeComponent } from './core/scip-home/scip-home.component';
import { GeneralComponent } from './core/general/general.component';

const routes: Routes = [
  {
    path: '',
    component: ScipHomeComponent
  },
  {
    path: 'scip',
    component: GeneralComponent
  },
  {
    path: 'scip/number',
    component: ScipDummyComponent
  },
  {
    path: 'scip/article',
    loadChildren : './feature/article33/article33.module#Article33Module'
  },
  {
    path: 'scip/faq',
    component: ScipFaqComponent
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
