import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {HostNameService } from './sample.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor(private router: Router, private hns: HostNameService) { }


  //  DEVDeploy = 'https://plcp-dev.srv.volvo.com/';
   DEV = 'https://scip-dev-euw-apim-01.azure-api.net';

  // QADeploy = 'https://scipportal-qa-euw-app-01.azurewebsites.net/';
   QA = 'https://scip-qa-euw-apim-01.azure-api.net';

  // PRODDepoy: 'https://plcp.srv.volvo.com/';
   PROD = 'https://scip-prod-euw-apim-01.azure-api.net';

  getEnvironment() {
    console.log(`###########`);
    console.log(this.hns.getHostname());
    console.log(`###########`);
    const domain = this.hns.getHostname();
    let backendApi;

    if (domain === 'localhost' || domain === 'plcp-dev.srv.volvo.com') {
      backendApi = this.DEV;
    } else if (domain === 'scipportal-qa-euw-app-01.azurewebsites.net'
      || domain === 'plcp-qa.srv.volvo.com') {
      backendApi = this.QA;
    } else if (domain === 'plcp.srv.volvo.com') {
      backendApi = this.PROD;
    }
    return backendApi;
  }
}
